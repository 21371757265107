import { Link, NavLink, Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <div className="main-container">
      <header>
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScImeCokxRnakdddS9H9WptZd9EtjfOpUcyxFbW4GpqnPGe6Q/viewform?usp=sf_link" rel="noreferrer" target="_blank">
              Contact Us
            </a>
          </li>
          <li>
            <NavLink to="create-moments">Create Moments</NavLink>
          </li>
        </ul>
      </header>
      <Routes>
        <Route
          path="/"
          element={
            <div className="row m-0 h-100">
              <div className="col-md-6 text-container">
                <h1>PhotographWala</h1>
                <h4 className="mb-4">Capturing Moments, Connecting Worlds</h4>
                <p>At Photographwala, we believe in the power of images to inspire, connect, and evoke emotions. Whether you're seeking a skilled photographer to capture your special moments or searching for captivating stock photographs to enhance your creative projects, we're here to bridge the gap and bring your vision to life.</p>
                <div className="d-flex mt-3">
                  <Link to="create-moments">Create&nbsp;Moments</Link>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLScImeCokxRnakdddS9H9WptZd9EtjfOpUcyxFbW4GpqnPGe6Q/viewform?usp=sf_link" className="join-btn" target="_blank" rel="noreferrer">
                    Join&nbsp;Us
                  </a>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          }
        />
        <Route
          path="/create-moments"
          element={
            <div className="contact-form">
              <iframe className="w-100 h-100" title="create-moments" src="https://docs.google.com/forms/d/e/1FAIpQLSdkPmvrdvN7ZOP_5IUruUGPbfYAukK3vYl0hVcvu7etR5GmoQ/viewform?embedded=true">
                Loading…
              </iframe>
            </div>
          }
        />
        <Route className="contact-form" path="/join-us" element={<div></div>} />
      </Routes>
    </div>
  );
}
